export function Footer() {
  return (
    <footer>
      © Tarnaspol Mateusz Tarnaski -{' '}
      <a href="https://paxmagnifica.com">Pax Magnifica Bellum Gloriosum.</a> |
      Support us -{' '}
      <a href="https://buymeacoffee.com/paxmagnifica" rel="nofollow">
        donate some trade goods
      </a>
    </footer>
  )
}
